import {motion} from 'framer-motion'


const Popup = ({ children }) => {




    return (
      <>
        <motion.div
                initial={{ opacity: 0, scale: 0 }}
          style={{
            transformOrigin: 'top bottom', marginBottom: "10%",
          }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 250,
            damping: 70,
          }}
        >
          {children}
        </motion.div>
      </>
    )
  
  }
  
  export default Popup