import React from 'react'
import DescriptionBanner from '../components/pagesComponents/DescriptionBanner'
import TwoVideoSection from '../components/pagesComponents/TwoVideoSection'
import Aboutyouvideo from '../assets/video/aboutyou/1.webm'
import image2 from '../assets/video/aboutyou/1.jpeg'
import image1 from '../assets/video/aboutyou/2.jpeg'
import Contact from '../components/Contact'


const Aboutyou = () => {
  const desc="We partnered with About You during their main show for Milan fashion week. Our creators Arianna and Benedetta, attended the shows creating content about the experience they attended."
  return (
    <>
    <DescriptionBanner  video={Aboutyouvideo} title={"About you"}  desc={desc} />
    <TwoVideoSection image1={image1} image2={image2} isLeft={false}  section2paddingTop={true}  />
    <Contact />


    </>
  )
}

export default Aboutyou