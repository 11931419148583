import React, { useEffect, useRef } from "react";
import downArrow from "../assets/img/icon/downarrow.svg";
import { useTheme } from "../Context/useTheme";
import { useInView } from "framer-motion";
import HeadingAnimation from "./HeadingAnimation";

const TalentBanner = ({ setisLight }) => {
  const targetRef = useRef(null);
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  return (
    <>
      <div
        className="container-fluid bg-dark section-padding talent-banner custom-padding "
        ref={targetRef}
      >
        <div className="row" ref={elementRef}>
          <div className="col-12 col-md-12 col-lg-12">
            <HeadingAnimation
              elementType={"h1"}
              classes="text-white text-uppercase big-heading  font-telegraf "
            >
              <span>UNLOCK A</span>
              <span className=" text-primary   font-antonio  fw-bolder f-65">
                {" "}
                WORLD OF TALENT{" "}
              </span>
              <span className="font-telegraf mx-2"> with </span>
              <span
                className={`font-antonio fw-bolder pt-4 f-62 ${
                  isInView ? "heading-animation" : ""
                }`}
              >
                unique
              </span>{" "}
              <span
                className={`font-antonio fw-bolder pt-4 f-62 ${
                  isInView ? "heading-animation" : ""
                }`}
              >
                personalities
              </span>
            </HeadingAnimation>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-4  col-lg-3 col-9 ">
            <p className="text-white    mx-auto justified-text">
              We work with creators who have a very individual way to produce
              content and establish{" "}
              <span className="fw-bold">
                real communication with their audience.
              </span>
            </p>
          </div>

          <div className="col-md-4 col-lg-3   col-9 ">
            <p className="text-white   justified-text pr-5px">
              We support them in every aspect of their growth process, from the
              content strategy and production, to the events and the brand
              partnerships.
              <span className="text-primary fw-bold"> We value talent.</span>
            </p>
          </div>
          <div className="position-relative col-md-2 offset-lg-4 col-lg-2 col-2 d-flex algin-items-start justiy-content-center">
            <img
              src={downArrow}
              alt=""
              className="img-fluid   mx-auto my-auto mobile-talent-arrow"
            />{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentBanner;
