import React, { useEffect, useRef } from "react";
// import bg from "../assets/img/banner/whatwedo.png";
import video from "../assets/video/whatwedo/whatwedo.mp4";
import videoMobile from "../assets/video/whatwedo/mobile.mp4";

import { useTheme } from "../Context/useTheme";
import { useInView } from "framer-motion";
const WhatWeDoBanner = ({databanner}) => {
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);

  // const color = useTransform(scrollYProgress, (pos) => {
  //   console.log(pos,"banner")
  //   return pos > 0.81 ? toggleTheme("light"):toggleTheme("light");
  // });
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  return (
    <>
      <div
        ref={targetRef}
        className="bg-dark vh-100    mobile-4-vh  overflow-hidden"
      >
        <div className="  d-flex  align-items-center align-items-md-start justify-content-center h-100 what-we-do-video-container">
          {/* pc vide0 */}
          <video
            className="w-100  h-100  d-none d-md-block"
            src={databanner?databanner:video}
            playsInline
            autoPlay
            muted
            loop
          ></video>
          <video
            className="w-100  h-100 mt-md-0 mt-5  d-md-none d-block"
            src={databanner?databanner:videoMobile}
            playsInline
            autoPlay
            muted
            loop
          ></video>
        </div>
      </div>
    </>
  );
};

export default WhatWeDoBanner;
