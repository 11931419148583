import React, { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";
import { useTheme } from "../Context/useTheme";
import { useNavigate } from "react-router-dom";
import HeadingAnimation from "./HeadingAnimation";

const WhatWeDoSection = () => {
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();
  const sectionViewCheck = useInView(targetRef);

  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("dark");
      toggleisHome(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  const navigate = useNavigate();

  return (
    <>
      <div
        ref={targetRef}
        className="container-fluid what-we-do custom-padding bg-light"
      >
        <div className="row" ref={elementRef}>
          <div className="d-block  d-lg-none  mb-4">
            <div className="col-md-12 col-12 col-lg-12">
              <h1
                className={`text-antonio text-dark fw-bolder text-end text-uppercase inline-twentyfour ${
                  isInView ? "heading-animation" : ""
                }`}
              >
                <span className="fs-4 mx-4 inline-fifteen">02</span>
                What We DO
              </h1>
              <motion.hr
                className="inline-twentyfive"
                initial={{ width: "0%" }}
                whileInView={{ width: "100%" }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 1,
                  duration: 0.5,
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 col-lg-6">
            <div className="d-flex flex-column align-items-start justify-content-around inline-twentythree">
              <span className="outlined  border border-2 border-dark">
                Production
              </span>
              <span className="outlined  border border-2 border-dark">
                Social strategy
              </span>
              <span className="outlined  border border-2 border-dark">
                TALENT MANAGEMENT
              </span>
              <span className="outlined  border border-2 border-dark">
                Content creation UGC
              </span>
              <span className="outlined  border border-2 border-dark">
                Influencer experience
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 col-lg-6" re>
            <div className="d-none  d-lg-block row mb-4">
              <div className="col-md-12 col-12 col-lg-12">
                <HeadingAnimation
                  elementType="h1"
                  classes={`text-antonio text-dark fw-bolder text-end text-uppercase inline-twentyfour  ${
                    isInView ? "heading-animation" : ""
                  }`}
                >
                  <span className="fs-4 mx-4 inline-fifteen">02</span>
                  What We DO
                </HeadingAnimation>
                <motion.hr
                  className="inline-twentyfive"
                  initial={{ width: "0%" }}
                  whileInView={{ width: "120%" }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                    delay: 1,
                    duration: 0.5,
                  }}
                />
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.7,
                duration: 0.5,
              }}
              className="row mb-5 justify-content-end"
            >
              <div className="pl-20 col-md-12 col-12 col-xl-8  col-lg-10 text-right me-3 pr-40">
                <p className="justified-text text-dark mt-3  pr-60 ">
                  Premium ID means{" "}
                  <span className="text-primary fw-bolder">
                    premium identities
                  </span>
                  , it represent the uniqueness of each talent we manage and the
                  goal we want to reach for our clients and partners.
                </p>
                <p className="mt-4 justified-text text-dark pr-60">
                  We partner with over 60 brands applying the latest digital
                  trends to
                  <span className="fw-bolder">
                    {" "}
                    increase their visibility and engagement
                  </span>{" "}
                  through the power of the Creator Economy.
                </p>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="row mb-5">
          <div
            className="col-md-12 text-center 
          "
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 1,
                duration: 0.5,
              }}
              className="stroked-wrapper"
            >
              <div
                onClick={() => navigate("whatwedo")}
                className="stroked inline-twentysix "
                data-content="DISCOVER"
              >
                DISCOVER
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDoSection;
