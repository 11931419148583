import React from "react";
import WhatWeDo from "../components/WhatWeDo";
import OurTalents from "../components/OurTalents";
import HomeConnecting from "../components/HomeConnecting";
import Achieve from "../components/Achieve";
import Project from "../components/Projects";
import Contact from "../components/Contact";
import BrandSlider from "../components/BrandSlider";
import ProjectDesktopHome from "../components/ProjectDesktopHome";
import HomeBannerTwo from "../components/HomeBannerTwo";

const Home = () => {
  return (
    <>
      <HomeBannerTwo />
      <HomeConnecting />
      <OurTalents />
      <WhatWeDo />
      <Achieve />
      <div className="d-none  d-lg-block">
        <ProjectDesktopHome />
      </div>
      <div className="d-block  d-lg-none">
        <Project />
      </div>
      <BrandSlider />
      <Contact />
    </>
  );
};

export default Home;
