import React from 'react'
import DescriptionBanner from '../components/pagesComponents/DescriptionBanner'
import video from "../assets/video/Superga/final.MOV"
import Contact from '../components/Contact'

const Superga = () => {
  const desc = "We partnered with Superga for a project focused on content creation. Our talent Benedetta Bottin produced a promotional video, subsequently used on Superga's official channels."
  return (
    <>
    <DescriptionBanner desc={desc} video={video} title={"Superga"}  />
    <Contact />
    </>
  )
}

export default Superga