import React from "react";
import TwoVideoBanner from "../components/pagesComponents/TwoVideoBanner";
import OurTask from "../components/pagesComponents/OurTask";
import StyleInfoSection from "../components/pagesComponents/StyleInfoSection";
import ResumeSingleVideoSection from "../components/pagesComponents/ResumeSingleVideoSection";
import ThreePictureDescriptionSection from "../components/pagesComponents/ThreePictureDescriptionSection";
import video1 from "../assets/video/gaelleExp/1.mp4";
import video2 from "../assets/video/gaelleExp/2.mp4";

import image1 from "../assets/video/gaelleExp/1.JPG";

import image2 from "../assets/video/gaelleExp/2.jpg";

import image3 from "../assets/video/gaelleExp/3.jpg";

import image4 from "../assets/video/gaelleExp/4.jpg";

import image5 from "../assets/video/gaelleExp/5.jpg";
import image6 from "../assets/video/gaelleExp/6.jpg";
import image7 from "../assets/video/gaelleExp/7.JPG";
import image9 from "../assets/video/gaelleExp/9.jpg";

import image8 from "../assets/video/gaelleExp/8.png";
import Contact from "../components/Contact";

const GaellePositanoexperience = () => {
  const taskdesc =
    "We organized an experience for Gaelle Paris with the aim of promoting the brand's new summer collections. The initiative took place in the charming locations of Amalfi and Positano.";
  const task = [
    "CONTENT CREATION",
    "PRODUCTION",
    "INFLUENCER MARKETING",
    "BRAND EXPERIENCE",
  ];
  const resumedesc =
    "During this experience, our talent Benedetta Bottin shared content about her activities during the three days on the Amalfi Coast, creating an amount of 3 reel and Tik Tok and 12 instagram stories.";
  const lastdesc =
    "The content has been shared on the brand's social channels, as well as on Carolina’s profiles.";
  return (
    <>
      <TwoVideoBanner
        resource1={video1}
        resource2={image8}
        title={"Gaelle Positano experience"}
      />
      <OurTask desc={taskdesc} task={task} heading={"THE PROJECT"} />
      <ResumeSingleVideoSection
        desc={resumedesc}
        resource1={video2}
        secondVideo={false}
        righttext={"EXPERIENCE "}
        isLast={false}
        heading={"In details"}
      />
      {/* <StyleInfoSection resource1={image3} resource2={image4} resource3={image5} resource4={image6}/> */}
      <ThreePictureDescriptionSection
        desc={lastdesc}
        resource1={image9}
        resource2={image5}
        resource3={image1}
        isLast={true}
      />
      <Contact />
    </>
  );
};

export default GaellePositanoexperience;
