import React from 'react'
import { useLocation } from "react-router-dom";
import ProjectFilter from '../components/ProjectFilter'
import aboutyou from "../assets/img/allprojectthumbs/aboutyou.png";
import calliope from "../assets/img/allprojectthumbs/calliope.png";
import durex from "../assets/img/allprojectthumbs/durex.png";
import gaelleexp from "../assets/img/allprojectthumbs/gpexp.png";
import pgsound from "../assets/img/allprojectthumbs/pbpsound.png";
import pbstour from "../assets/img/allprojectthumbs/pbstour.png";
import riverisland from "../assets/img/allprojectthumbs/riverisland.png";
import superga from "../assets/img/allprojectthumbs/superga.png";
import guess from "../assets/img/allprojectthumbs/guess.png";
import lancome from "../assets/img/allprojectthumbs/lancome.png";
import estee from "../assets/img/allprojectthumbs/estee.png";
import puma from "../assets/img/allprojectthumbs/puma.png";
import charlotte from '../assets/img/allprojectthumbs/charlotte.png';
import gaellefashionweek from '../assets/img/allprojectthumbs/gaellefashionweek.png';


const ProjectFilterPage = () => {
    const {pathname} = useLocation();
    const currentPath = pathname.split('/').pop()
    const projectData = [
        {
            name: 'Content Creation',
            type: 'content',
            projects:[
                {
                    name: 'Guess',
                    img:  guess,
                    path: 'guess'
                },
                {
                    name: 'Lancome',
                    img: lancome,
                    path: 'lancome'
                },
                {
                    name: 'Estee Lauder',
                    img: estee,
                    path: 'estee'
                },
                {
                    name: 'Puma',
                    img: puma,
                    path: 'puma'
                },
                {
                    name: 'Superga',
                    img:superga,
                    path: 'superga'
                },
                {
                    name: 'Durex',
                    img: durex,
                    path: 'durex'
                },
                {
                    name: 'River Island',
                    img: riverisland,
                    path: 'River Island'
                }
            ]

        },
        {
            name: 'Influencer  Marketing',
            type: 'influencer',
            projects:[
                {
                    name: 'Pull and Bear store tour',
                    img:  pbstour,
                    path: 'pullandbear'
                },
                {
                    name: 'Gaelle fashion week ',
                    img: gaellefashionweek,
                    path: 'gaellefashionweek'
                },
                {
                    name: 'Charlotte Tilbury',
                    img: charlotte,
                    path: 'charlottetilbury'
                },
                {
                    name: 'About You',
                    img: aboutyou,
                    path: 'aboutyou'
                },
                {  
                    name: 'Gaelle Positano Experience',
                    img: gaelleexp,
                    path: 'gaellepositanoexperience'
                },{
                    name: 'Pull and Bear Primavera Sound',
                    img: pgsound,
                    path: 'pullandbear'
                }
                
            ]
        },
        {
            name: 'Production',
            type: 'production',
            projects:[
                {
                    name: 'Pull and Bear store tour',
                    img: pbstour,
                    path: 'pullandbear'
                },
                {
                    name: 'Gaelle fashion week ',
                    img: gaellefashionweek,
                    path: 'gaellefashionweek'
                },
                {
                    name: 'Gaelle Positano experience',
                    img: gaelleexp,
                    path: 'gaellepositanoexperience'
                }
            ]
        }
        
    ]

  return (
   <ProjectFilter projectData={projectData.find(e=>currentPath === e.type)} />
  )
}

export default ProjectFilterPage