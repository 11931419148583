import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
const HeadingAnimation = ({
  classes,
  elementType,
  text,
  children,
  ...props
}) => {
  const elementRef = useRef(null);
  const isInView = useInView(elementRef, { once: true });
  console.log(isInView, "isInViewHeadign");
  const MotionComponent = motion[elementType];
  // const isInViewClasses = isInView ? `${classes} ` : `${classes} `; Old one
  const isInViewClasses = `${classes} `;
  if (text && !children) {
    return (
      <MotionComponent ref={elementRef} className={isInViewClasses} {...props}>
        {text}
      </MotionComponent>
    );
  }
  if (text && children) {
    return (
      <MotionComponent ref={elementRef} className={isInViewClasses} {...props}>
        {text}
        {children}
      </MotionComponent>
    );
  }
  return (
    <MotionComponent ref={elementRef} className={isInViewClasses} {...props}>
      {children}
    </MotionComponent>
  );
};

export default HeadingAnimation;
