import { motion, useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../Context/useTheme";
import HeadingAnimation from "./HeadingAnimation";

const TalkToUs = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step12, setStep12] = useState("start");
  const [step13, setStep13] = useState("start");
  const [step14, setStep14] = useState("start");
  const [isOpen2, setIsOpen2] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sociallink, setSociallink] = useState("");

  const subForm = (e) => {
    if (name === "" || email === "" || message === "" || sociallink === "") {
      alert("Please fill all the fields");
      return;
    }
    const url = "https://premiumidapi.dev-sh.xyz/api/contactus";
    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("sociallink[]", sociallink);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpen1(false);
        setIsOpen2(true);
        console.log(data);
      })

      .catch((error) => console.error("Error:", error));
  };
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, y: "-100%", display: "none" },
  };
  const variantsStep2 = {
    open: { opacity: 1, y: 0, display: "block" },
    closed: { opacity: 0, y: "100%", display: "none" },
  };
  const variantsStep21 = {
    open: { opacity: 1, x: 0, display: "block" },
    closed: { opacity: 0, x: "-100%", display: "none" },
  };
  const variantsStep22 = {
    start: { opacity: 0, x: "100%", display: "none" },
    acitve: { opacity: 1, x: 0, display: "block" },
    end: { opacity: 0, x: "-100%", display: "none" },
  };
  const variantsStep23 = {
    start: { opacity: 0, x: "100%", display: "none" },
    acitve: { opacity: 1, x: 0, display: "block" },
    end: { opacity: 0, x: "-100%", display: "none" },
  };
  const variantsStep3 = {
    open: { opacity: 1, x: 0, display: "block" },
    closed: { opacity: 0, x: "100%", display: "none" },
  };
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  return (
    <div
      ref={targetRef}
      className="position-relative contact-top talk-to-us bg-dark  d-flex aligin-items-start align-items-md-center  justify-content-center  overflow-hidden"
    >
      {isOpen ? (
        <div className="step1 text-primary col-xl-10 col-12">
          <HeadingAnimation
            elementType={"h1"}
            classes="text-white font-telegraf big-heading text-uppercase mx-auto d-block mb-5"
          >
            We always aim for{" "}
            <span
              className={`text-primary font-antonio fw-bold ${
                sectionViewCheck && "heading-animation"
              }`}
            >
              greatness.
            </span>{" "}
          </HeadingAnimation>{" "}
          <div className="contact-btn-mobile text-center">
            <button
              onClick={() => {
                setIsOpen((isOpen) => !isOpen);
                setIsOpen1(true);
              }}
              className=" talk-btn  mt-md-auto mt-5"
            >
              Talk to us{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="align-middle"
              >
                <path
                  d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                  stroke="#F1F2F2"
                  stroke-width="2"
                />
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <>
          <motion.div
            animate={isOpen1 ? "open" : "closed"}
            variants={variantsStep2}
            transition={{
              type: "spring",
              stiffness: 10,
              damping: 5,
            }}
            className="step2 px-4 text-primary col-md-7 col-12"
          >
            <motion.div
              animate={step1 ? "open" : "closed"}
              variants={variantsStep21}
              transition={{
                type: "spring",
                stiffness: 10,
                damping: 5,
              }}
              className="content-move text-center mb-3  text-uppercase"
            >
              <div className="d-flex d-md-inline flex-column align-items-start">
                <p className="text-light fs-5 mt-3 d-inline float-start py-auto  ">
                  Step 1/4
                </p>
                <p className="text-white fw-bolder fs-1 d-inline  py-auto ">
                  You Are . . .
                </p>
              </div>

              <br />

              <input
                type="text"
                className="form-control talk-us-input"
                onChange={(e) => setName(e.target.value)}
                placeholder="your name and company"
              />
            </motion.div>
            <motion.div
              animate={step12}
              variants={variantsStep22}
              transition={{
                type: "spring",
                stiffness: 10,
                damping: 5,
              }}
              className="content-move text-center mb-3  text-uppercase"
            >
              <div className="d-flex d-md-inline flex-column align-items-start">
                <p className="text-light mt-3 fs-5 d-inline float-start py-auto  ">
                  Step 2/4
                </p>
                <p className="text-white fw-bolder fs-1 d-inline  py-auto ">
                  Your Message . . .
                </p>
              </div>
              <br />
              <input
                type="text"
                className="form-control talk-us-input"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="I would . . ."
              />
            </motion.div>
            <motion.div
              animate={step13}
              variants={variantsStep23}
              transition={{
                type: "spring",
                stiffness: 10,
                damping: 5,
              }}
              className="content-move text-center mb-3  text-uppercase"
            >
              <div className="d-flex d-md-inline flex-column align-items-start">
                <p className="text-light mt-3 fs-5 d-inline float-start py-auto  ">
                  Step 3/4
                </p>
                <p className="text-white fw-bolder fs-1 d-inline  py-auto ">
                  Your Contacts . . .
                </p>
              </div>
              <br />
              <input
                type="text"
                className="form-control talk-us-input"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail, phone number . . ."
              />
            </motion.div>
            <motion.div
              animate={step14}
              variants={variantsStep23}
              transition={{
                type: "spring",
                stiffness: 10,
                damping: 5,
              }}
              className="content-move text-center mb-3  text-uppercase "
            >
              <div className="d-flex d-md-inline flex-column align-items-start">
                <p className="text-light mt-3 fs-5 d-inline float-start py-auto  ">
                  Step 4/4
                </p>
                <p className="text-white fw-bolder fs-1 d-inline  py-auto ">
                  Your Socials . . .
                </p>
              </div>
              <br />
              <input
                type="text"
                className="form-control talk-us-input"
                onChange={(e) => setSociallink(e.target.value)}
                placeholder="Social links . . ."
              />
            </motion.div>

            {step1 && (
              <div className="d-flex align-items-center  justify-content-end mt-5">
                {/* <button
                  className="contact-talk-btn disabled"
                    onClick={() => {
                      setIsOpen1(false);
                      setIsOpen(false)
                      setStep1(false)}}
                    
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M8.47656 19.5C8.47656 18.758 7.74356 17.65 7.00156 16.72C6.04756 15.52 4.90756 14.473 3.60056 13.674C2.62056 13.075 1.43256 12.5 0.476563 12.5M0.476563 12.5C1.43256 12.5 2.62156 11.925 3.60056 11.326C4.90756 10.526 6.04756 9.479 7.00156 8.281C7.74356 7.35 8.47656 6.24 8.47656 5.5M0.476563 12.5L23.0015 12.5"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>{" "}
                  Prev
                </button> */}
                <button
                  className="contact-talk-btn"
                  onClick={() => {
                    setStep12("active");
                    setStep1(false);
                  }}
                >
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>
                </button>
              </div>
            )}
            {step12 === "active" && (
              <div className="d-flex align-items-center  justify-content-between mt-5">
                <button
                  className="contact-talk-btn"
                  onClick={() => {
                    setStep1(true);
                    setStep12("start");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M8.47656 19.5C8.47656 18.758 7.74356 17.65 7.00156 16.72C6.04756 15.52 4.90756 14.473 3.60056 13.674C2.62056 13.075 1.43256 12.5 0.476563 12.5M0.476563 12.5C1.43256 12.5 2.62156 11.925 3.60056 11.326C4.90756 10.526 6.04756 9.479 7.00156 8.281C7.74356 7.35 8.47656 6.24 8.47656 5.5M0.476563 12.5L23.0015 12.5"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>{" "}
                  Prev
                </button>
                <button
                  className="contact-talk-btn"
                  onClick={() => {
                    setStep13("active");
                    setStep12("end");
                  }}
                >
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>
                </button>
              </div>
            )}
            {step13 === "active" && (
              <div className="d-flex align-items-center  justify-content-between mt-5">
                <button
                  className="contact-talk-btn"
                  onClick={() => {
                    setStep12("active");
                    setStep13("start");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M8.47656 19.5C8.47656 18.758 7.74356 17.65 7.00156 16.72C6.04756 15.52 4.90756 14.473 3.60056 13.674C2.62056 13.075 1.43256 12.5 0.476563 12.5M0.476563 12.5C1.43256 12.5 2.62156 11.925 3.60056 11.326C4.90756 10.526 6.04756 9.479 7.00156 8.281C7.74356 7.35 8.47656 6.24 8.47656 5.5M0.476563 12.5L23.0015 12.5"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>{" "}
                  Prev
                </button>
                <button
                  className="contact-talk-btn"
                  onClick={() => {
                    setStep14("active");
                    setStep13("end");
                  }}
                >
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>
                </button>
              </div>
            )}
            {step14 === "active" && (
              <div className="d-flex align-items-center  justify-content-between mt-5">
                <button
                  className="contact-talk-btn"
                  onClick={() => {
                    setStep13("active");
                    setStep14("start");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M8.47656 19.5C8.47656 18.758 7.74356 17.65 7.00156 16.72C6.04756 15.52 4.90756 14.473 3.60056 13.674C2.62056 13.075 1.43256 12.5 0.476563 12.5M0.476563 12.5C1.43256 12.5 2.62156 11.925 3.60056 11.326C4.90756 10.526 6.04756 9.479 7.00156 8.281C7.74356 7.35 8.47656 6.24 8.47656 5.5M0.476563 12.5L23.0015 12.5"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>{" "}
                  Prev
                </button>
                <button
                  className="contact-talk-btn"
                  onClick={() => {
                    subForm();
                  }}
                >
                  Finish
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="py-auto"
                  >
                    <path
                      d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>
                </button>
              </div>
            )}
          </motion.div>
          <motion.div
            animate={isOpen2 ? "open" : "closed"}
            variants={variantsStep3}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 40,
              delay: 0.3,
              duration: 2,
            }}
            className="step1 text-primary col-10 text-center"
          >
            <h1 className="text-white font-telegraf my-4  text-uppercase fw-bolder">
              Thank You
            </h1>{" "}
            <p className="text-white text-center my-3">
              You will be contacted soon by one of our premium team.
            </p>
            <div className="d-flex align-items-center mt-5  justify-content-center">
              <Link
                onClick={() => {
                  setIsOpen((isOpen) => !isOpen);
                  setIsOpen1(true);
                }}
                className="talk-btn "
                to={"/"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8.47656 19.5C8.47656 18.758 7.74356 17.65 7.00156 16.72C6.04756 15.52 4.90756 14.473 3.60056 13.674C2.62056 13.075 1.43256 12.5 0.476563 12.5M0.476563 12.5C1.43256 12.5 2.62156 11.925 3.60056 11.326C4.90756 10.526 6.04756 9.479 7.00156 8.281C7.74356 7.35 8.47656 6.24 8.47656 5.5M0.476563 12.5L23.0015 12.5"
                    stroke="#F1F2F2"
                    stroke-width="2"
                  />
                </svg>
                Back home{" "}
              </Link>
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default TalkToUs;
