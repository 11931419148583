import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import OurTask from '../components/pagesComponents/OurTask'
import StyleInfoLayoutTwo from '../components/pagesComponents/StyleInfoLayoutTwo'
import video from '../assets/video/calliope/1.MP4'
import image1 from '../assets/video/calliope/1.JPEG'
import image2 from '../assets/video/calliope/2.PNG'
import image3 from '../assets/video/calliope/3.JPEG'
import Contact from '../components/Contact'





const Calliope = () => {
  const taskdesc = "We collaborated with Calliope and our talent Ludovica Nicolò in an event focused on the theme of color analysis, aiming to help Ludovica to discover their seasonal colors. "

const task = ["CONTENT CREATION" ," INFLUENCER MARKETING ", "BRAND EXPERIENCE" , "EVENT"]

  return (
    <>
    <TwoVideoBanner resource1={video} resource2={image1} title={"Calliope"} resource2layout={true}  />
    <OurTask desc={taskdesc} task={task} heading={"The Project"} />
    <StyleInfoLayoutTwo resource1={image2} resource2={image3} />
    <Contact />



    </>
  )
}

export default Calliope