import { motion, useScroll, useTransform } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import Image1 from "../../assets/img/talentslider/1-1.png";
import Image12 from "../../assets/img/talentslider/1-2.png";
import Image14 from "../../assets/img/talentslider/1-4.jpg";
import Image15 from "../../assets/img/talentslider/1-5.jpg";
import Image16 from "../../assets/img/talentslider/1-6.png";

import Image2 from "../../assets/img/talentslider/2-1.png";
import Image22 from "../../assets/img/talentslider/2-2.png";
import Image24 from "../../assets/img/talentslider/2-4.jpg";
import Image25 from "../../assets/img/talentslider/2-5.jpg";
import Image26 from "../../assets/img/talentslider/2-6.png";

import Image3 from "../../assets/img/talentslider/3-1.png";
import Image32 from "../../assets/img/talentslider/3-2.png";
import Image33 from "../../assets/img/talentslider/3-3.png";
import Image34 from "../../assets/img/talentslider/3-4.jpg";
import Image35 from "../../assets/img/talentslider/3-5.jpg";

import { useInView } from "framer-motion";
import { useTheme } from "../../Context/useTheme";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import axios from "axios";

const TalentSliderDesktop = () => {
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();
  const sectionViewCheck = useInView(targetRef);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  const getTalent = async () => {
    try {
      const response = await axios.get(
        "https://premiumidapi.dev-sh.xyz/api/talent-user"
      );
      setData(response?.data?.data);
      console.log(response?.data?.data, "presss-->>>>");
    } catch (error) {
      console.log(error, "error-->>>>");
    }
  };

  useEffect(() => {
    getTalent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        ref={targetRef}
        className="bg-dark  talent-slider-section g-0 m-0 p-0  d-lg-block"
      >
        <div className="talent-marquee">
          <Marquee speed={200}>
            <span className="text-white me-3">OUR {"      "}</span>

            <span className="text-secondary me-3">PREMIUM {"      "}</span>
            <span className="text-primary me-3">CREATORS {"      "}</span>
          </Marquee>
        </div>
        <div
          className="row g-0  "
          style={{
            // y,
            position: "relative",
            top: "-7vw",
            zIndex: 1,
          }}
        >
          {[...new Set(data?.map((s) => s.row))]
            .sort()
            .map((row) =>
              data
                ?.filter((s) => s.row === row)
                .map((e, index) => (
                  <HorizontalScrollCarousel
                    key={`row-${row}-${index}`}
                    slideData={e?.talent}
                  />
                ))
            )}

          {/* <HorizontalScrollCarousel slideData={slider1} />
          <HorizontalScrollCarousel slideData={slider3} /> */}
        </div>
      </div>
    </>
  );
};

export default TalentSliderDesktop;

const HorizontalScrollCarousel = ({ slideData }) => {
  const targetRef = useRef(null);
  const mobileView = useMediaQuery("(max-width: 600px)");
  const tabletView = useMediaQuery("(min-width: 601px) and (max-width: 992px)");
  const desktopView = useMediaQuery("(min-width: 993px)");

  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  let scrollPercent;
  if (mobileView) {
    scrollPercent = "-80%";
  } else if (tabletView) {
    scrollPercent = "-75%";
  } else if (desktopView) {
    scrollPercent = "-50%";
  }

  const x = useTransform(scrollYProgress, [0, 1], ["1%", scrollPercent]);
  const navigate = (url) => {
    window.open(url, "_blank");
  };
  return (
    <section
      ref={targetRef}
      className="position-relative  bg-transparent horizontal-crousel "
      style={{ height: "300vh" }}
    >
      <div className="position-sticky top-10 d-flex talent-slider-container align-items-start overflow-hidden">
        <motion.div style={{ x }} className="d-flex gap-5">
          {slideData?.reverse()?.map((e) => {
            return (
              <div className="item w-100 position-relative">
                <img
                  src={e.image}
                  className="img-fluid rounded-3 w-100 h-100"
                  alt=""
                  onClick={() => navigate(e.image)}
                />
                <div className="content position-absolute  start-0 ps-3">
                  <p className="text-white  font-telegraf m-0">{e.name}</p>
                  <p
                    className="text-white  font-telegraf m-0 fs-6"
                    onClick={() => navigate(e.insta)}
                  >
                    @{e.insta.split("/")[3]}
                  </p>
                </div>
              </div>
            );
          })}
        </motion.div>
      </div>
    </section>
  );
};

const slider1 = [
  {
    img: Image1,
    name: "Caterina Carani",
    url: "https://www.instagram.com/caterinacarani/",
  },

  {
    img: Image12,
    name: "Giulia Tascione",
    url: "https://www.instagram.com/giuliatascione/",
  },
  // {
  //   img: Image13,
  //   name: "Elena Petrin",
  //   url: "https://www.instagram.com/elena.petrin/",
  // },
  {
    img: Image14,
    name: "Giorgia Castagna",
    url: "https://www.instagram.com/giorgiacastagnaa",
  },
  {
    img: Image15,
    name: "Samuele Casetto",
    url: "https://www.instagram.com/samuelecasetto",
  },

  {
    img: Image3,
    name: "Martina Cau",
    url: "https://www.instagram.com/skinbymarss/ ",
  },
];
const slider2 = [
  {
    img: Image2,
    name: "Nando Antonetti",
    url: "https://www.instagram.com/nandoantonetti",
  },

  {
    img: Image22,
    name: "Ludovica Nicolò",
    url: "https://www.instagram.com/ludovicanick/",
  },
  {
    img: Image35,
    name: "Gabriel Nobile",
    url: "https://www.instagram.com/gabrielnobile/ ",
  },

  // {
  //   img: Image23,
  //   name: "Giulia Cappelli",
  //   url: "https://www.instagram.com/giuliicappelli/",
  // },
  {
    img: Image24,
    name: "Senada",
    url: "https://www.instagram.com/s.e.ae/",
  },
  {
    img: Image25,
    name: "Alessandra Belda",
    url: "https://www.instagram.com/alessandrabelda/",
  },
  {
    img: Image26,
    name: "Jos Schenk",
    url: "https://www.instagram.com/jos_schenk/",
  },
];
const slider3 = [
  {
    img: Image33,
    name: "Serena e Letizia Reginelli",
    url: "https://www.instagram.com/ginger__twins/",
  },
  {
    img: Image34,
    name: "Greta Filippeschi",
    url: "https://www.instagram.com/gretafilippeschi/",
  },
  {
    img: Image16,
    name: "Giulia Testolina",
    url: "https://www.instagram.com/gggggggiiiu/",
  },
  // {
  //   img: Image32,
  //   name: "Anastasia Khavy",
  //   url: "https://www.instagram.com/anastasiia_khavy/",
  // },
];
