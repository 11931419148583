import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import OurTask from '../components/pagesComponents/OurTask'
import StyleInfoSection from '../components/pagesComponents/StyleInfoSection'
import video1 from '../assets/video/charlotte/1.MP4'
import image1 from '../assets/video/charlotte/1.JPG'
import image2 from '../assets/video/charlotte/2.JPG'
import image3 from '../assets/video/charlotte/3.JPG'
import image4 from '../assets/video/charlotte/4.JPG'
import image5 from '../assets/video/charlotte/5.JPG'
import image6 from '../assets/video/charlotte/6.JPG'
import image7 from '../assets/video/charlotte/7.JPG'
import image8 from '../assets/video/charlotte/8.JPG'
import Contact from '../components/Contact'




const CharlotteTilbury = () => {
  const taskdesc = "   We collaborated with Charlotte Tilbury during the Global Skin Summit in London, one of the most important beauty events worldwide. During these two days, our talent Martina Cau had the opportunity to represent the brand at the event, being one of the five Italian creators involved. "
 
 const task = ["CONTENT CREATION" , "INFLUENCER MARKETING" , "BRAND EXPERIENCE" , "EVENT"]
 
 const styledesc = "She participated in various activities throughout the three-day experience, including a dinner with the brand's team and a direct meeting with Charlotte Tilbury in person."
  return (

    <>
    <TwoVideoBanner resource1={video1} resource2={image8} title={"Charlotte Tilbury"}  />
    <OurTask desc={taskdesc} task={task} heading={"The PRoject"} />
    <StyleInfoSection  desc={styledesc}  resource1={image6} resource2={image1} resource3={image5} resource4={image3} isLast={true}/>
    <Contact />


    </>
  )
}

export default CharlotteTilbury