import React, { useRef } from "react";
import { Accordion } from "react-bootstrap";
import { motion, useInView } from "framer-motion";
import HeadingAnimation from "./HeadingAnimation";
// import { useTheme } from "../Context/useTheme";
const WhatWeDoAccordin = () => {
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  const targetRef = useRef();
  const data = [
    {
      heading: "Talent management",
      description:
        "We manage social media creators developing their public image and directing their brand deals.",
    },
    {
      heading: "social strategy",
      description:
        "We plan influencer marketing campaigns taking care of the organizational process, from the content idealization to the publication.",
    },
    {
      heading: "ugc creation",
      description:
        "We support the creativity of our talents, providing the brand with valued material to be used for their social communication. UGC allows brands and creators to perfectly match their visions.",
    },
    {
      heading: "PRODUCTION",
      description:
        "During our projects we fully take care of the production process, generating high value content utilizing the top photography and videography techniques",
    },
    {
      heading: "Influencer experience",
      description:
        "We bring influencer marketing into the physical world, designing significant sponsored experiences for brands such as trips and activities. We provide an innovative way of defining influencer marketing",
    },
  ];

  // const { toggleTheme, toggleisHome } = useTheme();
  // const sectionViewCheck = useInView(targetRef);
  // const { scrollYProgress } = useScroll({
  //   target: targetRef,
  //   offset: ["start end", "end end"],
  // });

  // const color = useTransform(scrollYProgress, (pos) => {
  //   return pos > 0.81 ? toggleTheme("light"):toggleTheme("dark");;
  // });
  // useEffect(() => {
  //   if (sectionViewCheck) {
  //     toggleisHome(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sectionViewCheck]);
  return (
    <>
      <div
        className="container-fluid  padding-t-10rem custom-padding min-vh-100 bg-light what-we-do-accordin"
        ref={targetRef}
      >
        <div className="row" ref={elementRef}>
          <div className="col-12 col-md-8 col-lg-6 py-2 py-md-5">
            <HeadingAnimation
              elementType={"h1"}
              classes={` text-uppercase big-heading fw-bold font-antonio text-dark ${
                isInView ? "heading-animation" : ""
              }`}
            >
              What We DO
            </HeadingAnimation>
            <motion.hr
              className="inline-twentyfive mt-4 d-block  d-lg-none"
              initial={{ width: "0%" }}
              whileInView={{ width: "70%" }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 1,
                duration: 0.5,
              }}
            />
          </div>
          <div className="col-lg-2">
            <motion.div
              initial={{ height: "0%" }}
              whileInView={{ height: "100%" }}
              transition={{
                type: "spring",
                stiffness: 70,
                damping: 40,
                delay: 0.3,
                duration: 3,
              }}
              className="what-we-do-line-black inline-twentyeight"
            ></motion.div>
          </div>
          <div className="col-md-4 col-lg-4 col-8 ">
            <p className="justified-text text-dark inline-twentyseven">
              Our goal is to combine latest digital techniques and trends to
              increase visibility and engagement through the power of the
              Creator Economy.
            </p>
          </div>
        </div>
        <div className="row mt-3 mt-md-5">
          <div className="col-md-12 col-lg-8   col-12 ">
            <Accordion>
              {data.map((e, i) => {
                return (
                  <>
                    <Accordion.Item eventKey={i} className="border-0  bg-light">
                      <Accordion.Header className="border-0  bg-light">
                        <h1 className="size-change bg-light">{e.heading}</h1>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="border-bottom border-dark pb-4 bg-light">
                          {e.description}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDoAccordin;
