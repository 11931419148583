
import checkFileType from '../../../utils/CheckFileType'
import Popup from '../../popupanimaitons'
import './style.css'
const TwoVideoBanner = ({ title, resource1, resource2 ,resource2layout }) => {
  return (
    <div className="container-fluid section-padding custom-padding min-vh-100  pb-5">
      <div className="row mb-5">
        <div className="col-12 col-sm-5 col-md-7">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="text-dark video-main-heading text-uppercase">
                {title}
              </div>
              <hr className='position-absolute start-0 video-heading-line' />
            </div>
            <div className="col-12 col-md-12">
              <Popup>

              <div className={`${resource2layout ?"video-two-wrapper-2" :"video-two-wrapper"}`}>
                <div className='item  ratio ratio-4x3 rounded' >
                {
                  checkFileType(resource2) === 'Video' ? <video className=' my-auto mx-auto ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                  : <img className='  ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                }

                </div>
              </div>
              </Popup>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-7 col-md-5">
          <Popup>

          <div className="video-description-video-wrapper mx-auto">
            <div className='mx-auto d-flex ratio ratio-4x3 rounded' >
            {
                  checkFileType(resource1) === 'Video' ? <video className=' my-auto mx-auto ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                  : <img className='  ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                }

            </div>
          </div>
            
          </Popup>
        </div>
      </div>
    </div>
  )
}

export default TwoVideoBanner