import { motion, useAnimation, useTransform, useScroll } from "framer-motion";
import './style.css'
import { useRef } from "react";
const OurTask = ({ desc, task ,isLast ,heading}) => {
    const controls = useAnimation();
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end end"],
    });
    useTransform(scrollYProgress, (pos) => {
        if (pos >= 1 && !isLast) {
            controls.start("end");
        }
        else if (pos < 0.99 && pos > 0.9) {
            controls.start("start");
        }
    });

    const Varients = {
        start: {
            opacity: 1,
            y: 0,
            x: 0,
            transition: { type: "spring", stiffness: 260, damping: 20, }
        },
        end: {
            opacity: 0,
            y: 600,
            x: -600,
            transition: { type: "spring", stiffness: 260, damping: 20, }
        }
    }

    return (
        <motion.div
            ref={targetRef}
            initial={{ background: "#f1f2f1", }}
            animate={{ background: '#202020', transition: { duration: 1, delay: 0.3, type: "spring", stiffness: 260, damping: 20, } }}
            className="contianer-fluid custom-padding section-padding min-vh-100">
            <div className="row mt-5">
                <div className="col-12 col-md-6 ">
                    <h1 className='our-task-heading text-uppercase mt-md-5 mt-1'>{heading}</h1>
                </div>
                <div className="col-12 col-md-1">
                    <motion.div className="task-line  "
                        style={{ transformOrigin: "top" }}
                        initial={{ rotate: 60, }}
                        whileInView={{ rotate: 0, }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.3, type: "spring", stiffness: 260, damping: 20, }}
                    />
                </div>
                <div className="col-12 col-md-5 ps-md-5 ps-0 ">
                    <motion.p className='our-task-description ps-md-4 ps-3  d-none d-md-block  my-5'
                        animate={controls}
                        variants={Varients}
                    >
                        {desc}
                    </motion.p>
                    <p className='our-task-description ps-md-4 ps-3 d-block d-md-none  my-5'

                    >
                        {desc}
                    </p>
                </div>

            </div>
            <div className="row mt-md-5 mt-1">
                <div className="col-12 col-md-12">
                    <div className="d-flex flex-md-row flex-column flex-wrap ourtask-text my-md-5  my-2">
                        {task?.map((item, index) => {
                            return (
                                <>

                                    <p className='our-task-text-item-heading text-uppercase'>{item}</p>
                                    {index !== task.length - 1 && <p className="d-none d-md-block"> / </p>}
                                </>
                            )
                        }
                        )}

                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default OurTask