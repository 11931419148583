import Popup from '../../popupanimaitons';
import './style.css'


const StyleInfoLayoutTwo = ({ resource1, resource2 }) => {


    return (
        <div className="container-fluid  custom-padding section-padding min-vh-100  d-flex align-items-lg-end style-section-layout2">

            <div className="row mt-5 ">
                <div className="col-lg-5 col-md-12 position-relative order-2  order-lg-1">
                    <Popup>
                        
                    <div className="style-info-section-layout2-wrapper1">
                     
                        <div className="item1 ratio ratio-4x3 rounded" >


                            <img className='  ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                        </Popup>

                </div>
                <div className="col-lg-7 col-md-12 order-1 order-lg-2">

                        <Popup>
                    <div className="style-info-section-layout2-wrapper2  position-relative">
                        <b className="style">STYLE</b>
                            
                        <div className="item1 ratio ratio-4x3 rounded" >


                            <img className='  ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                            </Popup> 
                    <div className="row">
                        <div className="col-10 col-lg-6 ">
                            <p className='mt-5  mx-auto image-text'  >
                                {/* Il team di premium ID si è occupato dello studio creativo e della realizzazione dei contenuti. La protagonista del progetto è stata la talent Yousra Chaouki. */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default StyleInfoLayoutTwo;
