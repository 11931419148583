import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  motion,
  useInView,
  useAnimation,
  useTransform,
  useScroll,
} from "framer-motion";
import { Tab, Nav, Col } from "react-bootstrap";
import { useTheme } from "../Context/useTheme";
import ImagePullandBearexperience from "../assets/img/otherworkproject/Image Pull and Bear experience.png";
import pullandbearpreview from "../assets/img/otherworkproject/pullandbearpreview.png";

const ProjectFilter = ({ projectData }) => {
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();
  const [activeKey, setActiveKey] = useState(null);
  const navigate = useNavigate();
  const handleTabSelect = (key) => {
    setActiveKey(key);
  };
  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("dark");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  const controls = useAnimation();
  useEffect(() => {
    if (sectionViewCheck) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, sectionViewCheck]);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  useTransform(scrollYProgress, (pos) => {
    if (sectionViewCheck) {
      return pos > 0.79 ? toggleTheme("light") : toggleTheme("light");
    }
  });

  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("dark");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);

  const [light, setLight] = useState("");

  return (
    <>
      <motion.div
        ref={targetRef}
        animate={controls}
        initial={{ backgroundColor: "#202020" }}
        variants={{
          visible: { backgroundColor: "#F1F2F2" },
          hidden: { backgroundColor: "#202020" },
        }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 60,
          delay: 0.3,
          duration: 1,
        }}
        className="container-fluid verticial-height   custom-padding section-padding overflow-hidden"
      >
        <motion.div
          initial={{ height: "0%", width: "0.2%", top: "15%", right: "49%" }}
          whileInView={{ height: "80%" }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 60,
            delay: 1,
            duration: 5,
          }}
          className=" d-lg-block d-none bg-secondary  position-absolute"
        ></motion.div>
        <div className="row">
          <div className="col-lg-12">
            <h2 className="heading-2 px-2 text-dark">{projectData.name}</h2>
          </div>
        </div>

        <div className="row inline-eighteen">
          <Col md={6} sm={12}>
            <Nav
              className="flex-column  h-100 algin-items-start justify-content-between"
              variant="tabs"
              onSelect={handleTabSelect}
              activeKey={activeKey}
            >
              {projectData.projects.map((project, index) => (
                <Nav.Item
                  key={index}
                  onClick={() => setLight(project.name)}
                  style={{
                    textDecoration:
                      light === project.name
                        ? "solid black underline"
                        : undefined,
                  }}
                  className="demo mt-4 customhoverjunaid text-dark"
                >
                  <Nav.Link
                    className="text-dark"
                    eventKey={`tab${index + 1}`}
                    onMouseOver={() => handleTabSelect(`tab${index + 1}`)}
                    onClick={() => navigate(`/${project.path}`)}
                  >
                    {project.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col md={6} sm={12} className="  d-lg-block d-none">
            <Tab.Container id="side-nav-tab" activeKey={activeKey}>
              {projectData.projects.map((project, index) => (
                <Tab.Content key={index}>
                  <Tab.Pane eventKey={`tab${index + 1}`}>
                    <img
                      src={project.img}
                      className="position-absolute  d-lg-block d-none  "
                      style={{
                        top: "15%",
                        right: "3%",
                      }}
                      alt=""
                    />
                  </Tab.Pane>
                </Tab.Content>
              ))}
            </Tab.Container>
          </Col>
        </div>
      </motion.div>
    </>
  );
};

export default ProjectFilter;
