import React from 'react'
import DescriptionBanner from '../components/pagesComponents/DescriptionBanner'
import video from "../assets/video/puma/1.mp4"
import Contact from '../components/Contact'

const Puma = () => {
  const desc ="We partnered with Puma in a content creation project for promoting their new Puma Palermo shoes. Our talents Greta Filippeschi created two videos shared on their Instagram and Tik Tik profiles."
  return (
    <>
    <DescriptionBanner desc={desc} video={video} title={"Puma"}  />
    <Contact />
    </>
  )
}

export default Puma