import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import OurTask from '../components/pagesComponents/OurTask'
import StyleInfoSection from '../components/pagesComponents/StyleInfoSection'
import ResumeSingleVideoSection from '../components/pagesComponents/ResumeSingleVideoSection'
import ThreePictureSection from '../components/pagesComponents/ThreePictureSection'

import video from '../assets/video/pullandbearexp/contenuti pull sound copia/1.mp4'
import image1 from '../assets/video/pullandbearexp/contenuti pull sound copia/1.JPG'

import image2 from '../assets/video/pullandbearexp/contenuti pull sound copia/2.JPG'
import image3 from '../assets/video/pullandbearexp/contenuti pull sound copia/3.JPG'
import image4 from '../assets/video/pullandbearexp/contenuti pull sound copia/4.JPG'
import image5 from '../assets/video/pullandbearexp/Benedetta y eda/6.jpg'
import image6 from '../assets/video/pullandbearexp/Benedetta y eda/2.jpg'
import image7 from '../assets/video/pullandbearexp/Benedetta y eda/3.jpg'
import image8 from '../assets/video/pullandbearexp/Benedetta y eda/4.jpg'
import image9 from '../assets/video/pullandbearexp/Benedetta y eda/5.jpg'
import image10 from '../assets/video/pullandbearexp/Benedetta y eda/8.jpg'
import image11 from '../assets/video/pullandbearexp/Benedetta y eda/9.jpg'
import image12 from '../assets/video/pullandbearexp/Benedetta y eda/10.jpg'
import image13 from '../assets/video/pullandbearexp/Benedetta y eda/11.jpg'



import video2 from '../assets/video/pullandbearexp/Benedetta y eda/1.mp4'
import Contact from '../components/Contact'



const GaellePositanoexperience = () => {

  const taskdesc  ="  We partnered with Pull & Bear for a special project during the Primavera Sound festival in Barcelona. An exclusive experience between music, fashion and fun."
  const task =["ADVERTISING" , "CONTENT CREATION" ,"INFLUENCER MARKETING"  ,"IGC CREATION","DIGITAL PR","BRAND EXPERIENCE"]
  const resumedesc="We managed the selection of Italian talents who traveled to Barcelona for three days to produce content for the brand. Three sponsored videos and a total of 12 organic content were created and shared."
 const styledesc="The content have been shared on the brand's social channels, as well as by the involved talents for showing their experience. During the three days of the  experience they created 12 videos and about 30 pictures. "
 
 return (
    <>
    <TwoVideoBanner  resource1={video} resource2={image13}  title={"Pull and Bear Primavera Sound"}  />
    <OurTask desc={taskdesc}  task={task} heading={"THE PROJECT"}/>
    <ResumeSingleVideoSection desc={resumedesc} resource1={image2}  secondVideo={false} righttext={'THE EXPERIENCE'} isLast={false} heading={"In details"}/>
    <StyleInfoSection desc={styledesc} resource1={image4} resource2={image3} resource3={image10} resource4={video2} isLast={false}/>
    <ThreePictureSection  resource1={image11} resource2={image12} resource3={image4}/>
    <Contact />

    </>
  )
}

export default GaellePositanoexperience;