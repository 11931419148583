
import Popup from '../../popupanimaitons'
import './style.css'
const DescriptionBanner = ({title,video,desc}) => {
  return (
    <div className="container-fluid section-padding custom-padding min-vh-100  pb-5">
      <div className="row mb-5">
        <div className="col-12 col-sm-5 col-md-7">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="text-dark project-main-heading text-uppercase">
               {title}
              </div>
              <hr className='position-absolute start-0 project-heading-line' />
            </div>
            <div className="col-10 col-md-7">
              <p className='project-description mx-auto'>
              {desc}</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-7 col-md-5">
        <Popup>
          <div className="project-description-video-wrapper mx-auto">


            <div className='mx-auto d-flex ratio ratio-4x3 rounded' >
              
              <video className=' my-auto mx-auto '  src={video} autoPlay muted style={{objectFit:"cover"}} />
            </div>
          </div>
        </Popup>
        </div>
      </div>
    </div>
  )
}

export default DescriptionBanner