import React from "react";
import TalkToUs from "../components/TalkToUs";
import Contact from "../components/Contact";

const Contactus = () => {
  return (
    <>
      <TalkToUs />
      <Contact />
    </>
  );
};

export default Contactus;
