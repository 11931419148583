import React, { useState, useRef, useEffect } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Points, PointMaterial } from '@react-three/drei'
import * as random from 'maath/random/dist/maath-random.esm'

export default function App() {
  const canvasRef = useRef();

  return (
    <Canvas ref={canvasRef} camera={{ position: [0, 0, 1] }}>
      <Stars canvasRef={canvasRef} />
    </Canvas>
  )
}

function Stars({ canvasRef, ...props }) {
  const ref = useRef()
  const { camera } = useThree();
  const [sphere] = useState(() => random.inSphere(new Float32Array(5000), { radius: 1.5 }))
  const [mousePosition, setMousePosition] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [moving, setMoving] = useState(false);
  const cameraMoveSpeed = 0.003; // Adjust this value to control the camera movement speed
  const starsRotationSpeed = 0.6;
  useFrame((state, delta) => {
    if (!moving) {
      ref.current.rotation.x -= (delta / -10)* starsRotationSpeed;
      ref.current.rotation.y -= (delta / -15)* starsRotationSpeed;
    } else {
      const { x, y } = mousePosition;
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const offsetX = (x - centerX) / centerX;
      const offsetY = (y - centerY) / centerY;

      // Update camera position based on mouse movement with speed factor
   
      camera.position.set(
        camera.position.x + offsetX * cameraMoveSpeed,
        camera.position.y + offsetY * cameraMoveSpeed,
        camera.position.z
      );
      camera.lookAt(0, 0, 0);
    }
  });

  useEffect(() => {
    let timeout;
  
    const handlePointerMove = (event) => {
      clearTimeout(timeout);
  
      const rect = canvasRef.current.getBoundingClientRect();
      const isInside = event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
  
      if (isInside) {
        setMoving(true);
        setMousePosition({
          x: event.clientX,
          y: event.clientY,
        });
      } else {
        setMoving(false);
      }
  
      // Set a timeout to set moving to false after 500 milliseconds of no mouse movement
      timeout = setTimeout(() => {
        setMoving(false);
      }, 50);
    };
  
    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerleave', () => {
      clearTimeout(timeout); // Clear the timeout when the mouse leaves
      setMoving(false);
    });
  
    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerleave', () => {
        clearTimeout(timeout);
        setMoving(false);
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <group>
      <group rotation={[0, 0, Math.PI / 4]} ref={ref}>
        <Points positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial
            transparent
            color="#ffffff"
            size={0.005}
            sizeAttenuation={true}
            depthWrite={false}
          />
        </Points>
      </group>
    
    </group>
  )
}
